import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button, { ButtonGroup } from '@atlaskit/button';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { FieldTextStateless as TextField } from '@atlaskit/field-text';
import Form, { Field as FormField } from '@atlaskit/form';
import Select from '@atlaskit/select';
import Flag, { FlagGroup } from '@atlaskit/flag';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Pagination from '@atlaskit/pagination';
import Tabs from '@atlaskit/tabs';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';

import debounce from 'lodash/debounce';
import isUndefined from 'lodash/isUndefined';
import Center from 'components/Center';
import Empty from 'components/Empty';
import InviteByFileForm from 'components/InviteByFileForm';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import TabContent from 'pages/Home/styled/TabContent';
import Content from 'pages/Home/styled/Content';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { isEmail, isRequired } from 'utils/validators';

import { getUserInfo } from 'pages/Home/actions';

import {
  changeCommentInvite,
  changeDeleteInviteModals,
  changeFlagsInvite,
  changeFullNameInvite,
  changeEmailInvite,
  changeInvitesNameFilter,
  changeInvitesPage,
  changeInvitesSort,
  changeInviteStaffByFileFlags,
  changeInviteStaffComment,
  changeInviteStaffEmail,
  changeInviteStaffFlags,
  changeInviteStaffFullName,
  changeInviteStaffName,
  changeInviteStaffText,
  changeInviteStaffModals,
  changeModalsInvite,
  changeNameInvite,
  changeRoleInvite,
  changeTextInvite,
  deleteInvite,
  getInvitesList,
  sendInvite,
  sendInviteStaff,
  sendInviteStaffByFile
} from '../actions';
import {
  makeSelectDeleteModals,
  makeSelectInvite,
  makeSelectInviteFlags,
  makeSelectInviteModals,
  makeSelectInvitesCount,
  makeSelectInvitesFilter,
  makeSelectInvitesList,
  makeSelectInvitesPage,
  makeSelectInvitesSort,
  makeSelectLoadingDelete,
  makeSelectLoadingInvite,
  makeSelectLoadingTable,
  makeSelectSettingsLoading,
  makeSelectInviteStaff,
  makeSelectInviteStaffFlags,
  makeSelectInviteStaffModals,
  makeSelectInvitesByFileCount,
  makeSelectInvitesByFileFlags
} from '../selectors';
import reducer from '../reducer';
import saga from '../saga';

import Field from '../styled/Field';
import FieldGroup from '../styled/FieldGroup';
import P from '../styled/P';
import InviteTable from '../table/InviteTable';
import TextArea from '@atlaskit/field-text-area';
import Container from '../styled/Container';

class Invites extends React.Component {
  constructor(props) {
    super(props);

    this.inviteFiles = [];
    this.state = {
      flags: [],
      nameFilter: '',
      filterWasChanged: false,
      isInviteByFile: 0,
      isInviteByFileInvalid: false,
      nameValid: true,
      emailValid: true,
      nameStaffValid: true,
      emailStaffValid: true,
      hasSeenPriceRaiseModal: false
    };

    this.sendChangedNameFilter = debounce(this.sendChangedNameFilter, 300);

    if (!isUndefined(this.props.location.state)) {
      const addModals = this.props.location.state.addModals;
      const addStaffModals = this.props.location.state.addStaffModals;

      if (!isUndefined(addModals)) {
        this.props.changeModalsInvite({
          modals: this.props.location.state.addModals
        });
      } else if (!isUndefined(addStaffModals)) {
        this.props.changeInviteStaffModals({
          modals: this.props.location.state.addStaffModals
        });
      }
    }

    this.props.getUserInfo();
    this.props.getInvitesList();
  }

  componentDidMount() {
    const hasSeen = localStorage.getItem('hasSeenPriceRaiseModal');
    this.setState({ hasSeenPriceRaiseModal: hasSeen === 'true' });
  }

  componentWillReceiveProps(nextProps) {
    if (!isUndefined(nextProps.location.state)) {
      const addModals = nextProps.location.state.addModals;
      const addStaffModals = nextProps.location.state.addStaffModals;

      if (!isUndefined(addModals)) {
        this.props.changeModalsInvite({
          modals: nextProps.location.state.addModals
        });
      } else if (!isUndefined(addStaffModals)) {
        this.props.changeInviteStaffModals({
          modals: nextProps.location.state.addStaffModals
        });
      }

      this.props.history.replace({
        pathname: '/invites',
        state: undefined
      });
    }
  }

  closeModal = id => {
    this.props.changeModalsInvite({
      modals: this.props.inviteModals.filter(i => i !== id)
    });
  };

  closeStaffModal = id => {
    this.props.changeInviteStaffModals({
      modals: this.props.inviteStaffModals.filter(i => i !== id)
    });
  };

  closePriceRaiseModal = () => {
    this.setState({ hasSeenPriceRaiseModal: true });
    localStorage.setItem('hasSeenPriceRaiseModal', 'true');
  };

  addFlag = () => {
    this.props.changeFlagsInvite({
      flags: [this.props.inviteFlags.length, ...this.props.inviteFlags.flags]
    });
  };

  removeFlag = id => {
    this.props.changeFlagsInvite({
      flags: this.props.inviteFlags.filter(v => v !== id)
    });
  };

  removeStaffFlag = id => {
    this.props.changeInviteStaffFlags({
      flags: this.props.inviteStaffFlags.filter(v => v !== id)
    });
  };

  removeInviteByFileFlag = id => {
    this.props.changeInviteStaffByFileFlags({
      flags: this.props.inviteStaffByFileFlags.filter(v => v !== id)
    });
  };

  onBreadcumbsClick = (event, pathname) => {
    event.preventDefault();
    this.props.history.push(pathname);
  };

  onChangeComment = event => {
    this.props.changeCommentInvite({
      comment: event.target.value
    });
  };

  onChangeStaffComment = event => {
    this.props.changeInviteStaffComment({
      comment: event.target.value
    });
  };

  onChangeFullName = event => {
    this.props.changeFullNameInvite({
      fullName: event.target.value
    });
  };

  onChangeStaffFullName = event => {
    this.props.changeInviteStaffFullName({
      fullName: event.target.value
    });
  };

  onChangeEmail = event => {
    this.setState({
      emailValid: isEmail(event.target.value)
    });
    this.props.changeEmailInvite({
      email: event.target.value
    });
  };

  onChangeStaffEmail = event => {
    this.setState({
      emailStaffValid: isEmail(event.target.value)
    });
    this.props.changeInviteStaffEmail({
      email: event.target.value
    });
  };

  onChangeName = event => {
    this.setState({
      nameValid: isRequired(event.target.value)
    });
    this.props.changeNameInvite({
      name: event.target.value
    });
  };

  onChangeStaffName = event => {
    this.setState({
      nameStaffValid: isRequired(event.target.value)
    });
    this.props.changeInviteStaffName({
      name: event.target.value
    });
  };

  onChangeNameFilter = event => {
    this.sendChangedNameFilter(event.target.value);
    this.setState({
      filterWasChanged: true,
      nameFilter: event.target.value
    });
  };

  onChangeRole = event => {
    this.props.changeRoleInvite({
      role: event.value
    });
  };

  onChangeText = event => {
    this.props.changeTextInvite({
      text: event.target.value
    });
  };

  onChangeStaffText = event => {
    this.props.changeInviteStaffText({
      text: event.target.value
    });
  };

  onDeleteInvite = id => {
    this.props.deleteInvite({
      inviteId: id
    });
  };

  onSendInvite = () => {
    const { invite } = this.props;
    const nameValid = isRequired(invite.get('name'));
    const emailValid = isEmail(invite.get('email'));

    if (nameValid && emailValid) {
      this.props.sendInvite();
    } else {
      this.setState({
        nameValid,
        emailValid
      });
    }
  };

  onSendInviteStaff = () => {
    const { inviteStaff } = this.props;
    const { isInviteByFile } = this.state;

    if (isInviteByFile === 1) {
      const files = this.inviteFiles;
      if (files.length > 0) {
        this.props.sendInviteStaffByFile({
          files
        });

        this.inviteFiles = [];
      } else {
        this.setState({
          isInviteByFileInvalid: true
        });
      }
    } else {
      const nameStaffValid = isRequired(inviteStaff.name);
      const emailStaffValid = isEmail(inviteStaff.email);

      if (nameStaffValid && emailStaffValid) {
        this.props.sendInviteStaff();
      } else {
        this.setState({
          nameStaffValid,
          emailStaffValid
        });
      }
    }
  };

  onSort = (key, order) => {
    this.props.changeInvitesSort({
      key,
      order
    });
  };

  onDropFile = files => {
    this.setState({
      isInviteByFileInvalid: false
    });

    this.inviteFiles = files;
  };

  onChangeTab = (tab, index) => {
    this.setState({
      isInviteByFile: index
    });
  };

  sendChangedNameFilter = invitesFilter => {
    this.props.changeInvitesNameFilter({
      invitesFilter
    });
  };

  render() {
    const {
      changeDeleteInviteModals,
      changeInvitesPage,
      deleteModals,
      invite,
      inviteFlags,
      inviteModals,
      invitesCount,
      invitesList,
      invitesPage,
      invitesSort,
      inviteStaff,
      inviteStaffFlags,
      inviteStaffModals,
      inviteStaffByFileCount,
      inviteStaffByFileFlags,
      loadingDelete,
      loadingInvite,
      loadingTable,
      settingsLoading
    } = this.props;
    const {
      emailStaffValid,
      emailValid,
      filterWasChanged,
      isInviteByFile,
      isInviteByFileInvalid,
      nameFilter,
      nameStaffValid,
      nameValid,
      hasSeenPriceRaiseModal
    } = this.state;

    let content = <Spinner />;

    if ((!settingsLoading && (invitesList.size > 0 || filterWasChanged)) || loadingTable) {
      const actions = (
        <ButtonGroup>
          <Button
            appearance="primary"
            onClick={() =>
              this.props.changeInviteStaffModals({
                modals: [1]
              })
            }
          >
            Пригласить на опрос
          </Button>
          <Button
            appearance="primary"
            onClick={() =>
              this.props.changeModalsInvite({
                modals: [1]
              })
            }
          >
            Пригласить пользователя
          </Button>
        </ButtonGroup>
      );

      const barContent = (
        <ButtonGroup>
          <TextField
            isLabelHidden
            label="hidden"
            onChange={this.onChangeNameFilter}
            placeholder="Имя или фамилия"
            value={nameFilter}
          />
        </ButtonGroup>
      );

      content = (
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={actions} bottomBar={barContent}>
              Приглашения
            </PageHeader>
          </GridColumn>
          <GridColumn medium={12}>
            <InviteTable
              data={invitesList}
              deleteModals={deleteModals}
              isLoading={loadingTable}
              isLoadingDelete={loadingDelete}
              onChangeDeleteModals={changeDeleteInviteModals}
              onDelete={this.onDeleteInvite}
              onSort={this.onSort}
              sortKey={invitesSort.get('key')}
              sortOrder={invitesSort.get('order')}
            />
            {!loadingTable && (
              <Center>
                <Pagination
                  value={invitesPage}
                  total={Math.ceil(invitesCount / 25)}
                  onChange={invitesPage => changeInvitesPage({ invitesPage })}
                />
              </Center>
            )}
          </GridColumn>
        </Grid>
      );
    } else if (!settingsLoading && invitesList.size === 0) {
      content = (
        <Container>
          <Empty header="Начните использовать наш сервис">
            <P>
              Если вы здесь впервые, рекомендуем ознакомиться с сервисом в разделе{' '}
              <Link to="/videos/2">Обучающее видео</Link>. Для того, чтобы отправить ссылку сотруднику
              или кандидату для прохождения опроса, нажмите на кнопку ниже.
            </P>
            <Button
              appearance="primary"
              onClick={() =>
                this.props.changeInviteStaffModals({
                  modals: [1]
                })
              }
            >
              Отправить опрос
            </Button>
          </Empty>
        </Container>
      );
    }

    const options = [];
    options[1] = { label: 'Менеджер', value: 1 };
    options[2] = { label: 'Администратор', value: 2, extra: 'extra' };

    const tabs = [
      {
        label: 'Разовое приглашение',
        content: (
          <TabContent>
            <p>Укажите имя человека и e-mail, на который необходимо отправить ссылку на опрос</p>
            <Form name="layout-example" onSubmit={() => { }} onReset={() => { }} method="GET">
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!nameStaffValid}
                    label="Имя"
                    name="name"
                    onChange={this.onChangeStaffName}
                    placeholder=""
                    required
                    shouldFitContainer
                    value={inviteStaff.name}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    label="Фамилия"
                    name="full_name"
                    onChange={this.onChangeStaffFullName}
                    placeholder=""
                    shouldFitContainer
                    value={inviteStaff.full_name}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextField
                    isInvalid={!emailStaffValid}
                    label="Email"
                    name="email"
                    onChange={this.onChangeStaffEmail}
                    placeholder=""
                    required
                    shouldFitContainer
                    value={inviteStaff.email}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextArea
                    enableResize={false}
                    label="Текст приглашения"
                    minimumRows={4}
                    name="text"
                    onChange={this.onChangeStaffText}
                    placeholder=""
                    shouldFitContainer
                    value={inviteStaff.welcome_text}
                  />
                </Field>
              </FieldGroup>
              <FieldGroup>
                <Field width="100%">
                  <TextArea
                    enableResize={false}
                    label="Комментарий"
                    minimumRows={4}
                    name="comment"
                    onChange={this.onChangeStaffComment}
                    placeholder=""
                    shouldFitContainer
                    value={inviteStaff.comment}
                  />
                </Field>
              </FieldGroup>
            </Form>
          </TabContent>
        )
      },
      {
        label: 'Массовое приглашение',
        content: (
          <TabContent>
            <InviteByFileForm
              invite={inviteStaff}
              isFileInvalid={isInviteByFileInvalid}
              onChangeText={this.onChangeStaffText}
              onChangeComment={this.onChangeStaffComment}
              onDropFile={this.onDropFile}
            />
          </TabContent>
        )
      }
    ];

    return (
      <Fragment>
        <Helmet>
          <title>Приглашения</title>
        </Helmet>
        <Page>
          {content}

          {inviteModals.map(id => (
            <Modal
              autoFocus={false}
              key={id}
              id={id}
              heading="Пригласить пользователя"
              onClose={this.closeModal}
              actions={[
                {
                  isLoading: loadingInvite,
                  onClick: this.onSendInvite,
                  text: 'Пригласить'
                },
                {
                  text: 'Отменить',
                  onClick: this.closeModal
                }
              ]}
              width="small"
            >
              <p>Укажите имя человека и e-mail, на который будет отправлено приглашение.</p>
              <Form name="layout-example" onSubmit={() => { }} onReset={() => { }} method="GET">
                <FieldGroup>
                  <Field width="100%">
                    <TextField
                      isInvalid={!nameValid}
                      label="Имя"
                      name="name"
                      onChange={this.onChangeName}
                      placeholder=""
                      required
                      shouldFitContainer
                      value={invite.get('name')}
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <TextField
                      label="Фамилия"
                      name="full_name"
                      onChange={this.onChangeFullName}
                      placeholder=""
                      shouldFitContainer
                      value={invite.get('full_name')}
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <TextField
                      isInvalid={!emailValid}
                      required
                      label="Email"
                      name="email"
                      onChange={this.onChangeEmail}
                      placeholder=""
                      shouldFitContainer
                      value={invite.get('email')}
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <FormField label="Роль" validateOnBlur={false}>
                      <Select
                        className="multi-select"
                        classNamePrefix="react-select"
                        defaultValue={options[invite.get('role')]}
                        menuPortalTarget={document.body}
                        onChange={this.onChangeRole}
                        options={options}
                        placeholder="Роль"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      />
                    </FormField>
                    <P marginTop="9px" color="#6B778C">
                      Администратор может приглашать других пользователей в компанию и имеет полный
                      доступ к данным всех менеджеров. Менеджер имеет доступ только к своим данным,
                      а данные других менеджеров он может только просматривать.
                    </P>
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <TextArea
                      enableResize={false}
                      label="Текст приглашения"
                      minimumRows={4}
                      name="text"
                      onChange={this.onChangeText}
                      placeholder=""
                      shouldFitContainer
                      value={invite.get('welcome_text')}
                    />
                  </Field>
                </FieldGroup>
                <FieldGroup>
                  <Field width="100%">
                    <TextArea
                      enableResize={false}
                      label="Комментарий"
                      minimumRows={4}
                      name="comment"
                      onChange={this.onChangeComment}
                      placeholder=""
                      shouldFitContainer
                      value={invite.get('comment')}
                    />
                  </Field>
                </FieldGroup>
              </Form>
            </Modal>
          ))}

          {inviteStaffModals.map(id => (
            <Modal
              autoFocus={false}
              key={id}
              id={id}
              heading="Отправить опрос"
              onClose={this.closeStaffModal}
              actions={[
                {
                  text: 'Отправить',
                  onClick: this.onSendInviteStaff,
                  isLoading: loadingInvite
                },
                {
                  text: 'Отменить',
                  onClick: this.closeStaffModal
                }
              ]}
              width="small"
            >
              <Tabs
                components={{ Content }}
                tabs={tabs}
                selected={tabs[isInviteByFile]}
                onSelect={this.onChangeTab}
              />
            </Modal>
          ))}

          <FlagGroup onDismissed={name => this.removeFlag(name)}>
            {inviteFlags.map(id => (
              <Flag
                isDismissAllowed
                id={id}
                icon={<Tick label="Success" />}
                key={`${id}`}
                title="Приглашение отправлено"
              />
            ))}
          </FlagGroup>

          <FlagGroup onDismissed={name => this.removeStaffFlag(name)}>
            {inviteStaffFlags.map(id => (
              <Flag
                isDismissAllowed
                id={id}
                icon={<Tick label="Success" />}
                key={`${id}`}
                title="Приглашение отправлено"
              />
            ))}
          </FlagGroup>

          <FlagGroup onDismissed={name => this.removeInviteByFileFlag(name)}>
            {inviteStaffByFileFlags.map(id => (
              <Flag
                description={id < 0 ? 'При отправке произошла ошибка. Попробуйте еще раз' : ''}
                isDismissAllowed
                id={id}
                icon={
                  id > 0 ? (
                    <Tick label="Success" />
                  ) : (
                    <Error label="Error" primaryColor={colors.R300} />
                  )
                }
                key={`${id}`}
                title={id > 0 ? `Опрос отправлен ${inviteStaffByFileCount} людям` : 'Ошибка'}
              />
            ))}
          </FlagGroup>
        </Page>

        {hasSeenPriceRaiseModal || (
          <Modal
            autoFocus={false}
            heading="Важная информация"
            onClose={this.closePriceRaiseModal}
            actions={[
              {
                text: 'Закрыть',
                onClick: this.closePriceRaiseModal,
              },
            ]}
            width="small"
          >
            <p>Уважаемые клиенты, в течение 6 лет существования сервиса мы удерживали стоимость опроса на одном уровне,
              но в силу актуальных для всего рынка обстоятельств мы вынуждены провести повышение цен на опросы с 1 января 2025 года.
            </p>
            <p>До конца года уровень цен будет оставаться прежним.</p>
          </Modal>
        )}
      </Fragment>
    );
  }
}

Invites.propTypes = {
  changeCommentInvite: PropTypes.func,
  changeDeleteInviteModals: PropTypes.func,
  changeFlagsInvite: PropTypes.func,
  changeFullNameInvite: PropTypes.func,
  changeEmailInvite: PropTypes.func,
  changeInvitesNameFilter: PropTypes.func,
  changeInvitesPage: PropTypes.func,
  changeInvitesSort: PropTypes.func,
  changeInviteStaffByFileFlags: PropTypes.func,
  changeInviteStaffComment: PropTypes.func,
  changeInviteStaffEmail: PropTypes.func,
  changeInviteStaffFlags: PropTypes.func,
  changeInviteStaffFullName: PropTypes.func,
  changeInviteStaffModals: PropTypes.func,
  changeInviteStaffName: PropTypes.func,
  changeInviteStaffText: PropTypes.func,
  changeModalsInvite: PropTypes.func,
  changeNameInvite: PropTypes.func,
  changeRoleInvite: PropTypes.func,
  changeTextInvite: PropTypes.func,
  deleteInvite: PropTypes.func,
  deleteModals: PropTypes.array,
  getInvitesList: PropTypes.func,
  getUserInfo: PropTypes.func,
  invite: PropTypes.object,
  inviteFlags: PropTypes.array,
  inviteModals: PropTypes.array,
  invitesCount: PropTypes.number,
  invitesFilter: PropTypes.string,
  invitesList: PropTypes.object,
  invitesPage: PropTypes.number,
  invitesSort: PropTypes.object,
  inviteStaff: PropTypes.object,
  inviteStaffFlags: PropTypes.array,
  inviteStaffModals: PropTypes.array,
  inviteStaffByFileCount: PropTypes.number,
  inviteStaffByFileFlags: PropTypes.array,
  loadingDelete: PropTypes.bool,
  loadingInvite: PropTypes.bool,
  loadingTable: PropTypes.bool,
  sendInvite: PropTypes.func,
  sendInviteStaff: PropTypes.func,
  sendInviteStaffByFile: PropTypes.func.isRequired,
  settingsLoading: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {
    changeCommentInvite: value => dispatch(changeCommentInvite(value)),
    changeDeleteInviteModals: value => dispatch(changeDeleteInviteModals(value)),
    changeFlagsInvite: value => dispatch(changeFlagsInvite(value)),
    changeFullNameInvite: value => dispatch(changeFullNameInvite(value)),
    changeEmailInvite: value => dispatch(changeEmailInvite(value)),
    changeInvitesNameFilter: value => dispatch(changeInvitesNameFilter(value)),
    changeInvitesPage: value => dispatch(changeInvitesPage(value)),
    changeInvitesSort: value => dispatch(changeInvitesSort(value)),
    changeInviteStaffByFileFlags: value => dispatch(changeInviteStaffByFileFlags(value)),
    changeInviteStaffComment: value => dispatch(changeInviteStaffComment(value)),
    changeInviteStaffEmail: value => dispatch(changeInviteStaffEmail(value)),
    changeInviteStaffFlags: value => dispatch(changeInviteStaffFlags(value)),
    changeInviteStaffFullName: value => dispatch(changeInviteStaffFullName(value)),
    changeInviteStaffModals: value => dispatch(changeInviteStaffModals(value)),
    changeInviteStaffName: value => dispatch(changeInviteStaffName(value)),
    changeInviteStaffText: value => dispatch(changeInviteStaffText(value)),
    changeModalsInvite: value => dispatch(changeModalsInvite(value)),
    changeNameInvite: value => dispatch(changeNameInvite(value)),
    changeRoleInvite: value => dispatch(changeRoleInvite(value)),
    changeTextInvite: value => dispatch(changeTextInvite(value)),
    deleteInvite: value => dispatch(deleteInvite(value)),
    getInvitesList: () => dispatch(getInvitesList()),
    getUserInfo: () => dispatch(getUserInfo()),
    sendInvite: () => dispatch(sendInvite()),
    sendInviteStaff: () => dispatch(sendInviteStaff()),
    sendInviteStaffByFile: value => dispatch(sendInviteStaffByFile(value))
  };
}

const mapStateToProps = createStructuredSelector({
  deleteModals: makeSelectDeleteModals(),
  invite: makeSelectInvite(),
  inviteFlags: makeSelectInviteFlags(),
  inviteModals: makeSelectInviteModals(),
  invitesCount: makeSelectInvitesCount(),
  invitesFilter: makeSelectInvitesFilter(),
  invitesList: makeSelectInvitesList(),
  invitesPage: makeSelectInvitesPage(),
  invitesSort: makeSelectInvitesSort(),
  inviteStaff: makeSelectInviteStaff(),
  inviteStaffFlags: makeSelectInviteStaffFlags(),
  inviteStaffModals: makeSelectInviteStaffModals(),
  inviteStaffByFileCount: makeSelectInvitesByFileCount(),
  inviteStaffByFileFlags: makeSelectInvitesByFileFlags(),
  loadingDelete: makeSelectLoadingDelete(),
  loadingInvite: makeSelectLoadingInvite(),
  loadingTable: makeSelectLoadingTable(),
  settingsLoading: makeSelectSettingsLoading()
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
const withReducer = injectReducer({ key: 'settings', reducer });
const withSaga = injectSaga({ key: 'settings', saga });

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(Invites);
